* {
  box-sizing: border-box;
  --bg: #1c1c1d;
  --bg2: #202022;
  --bg3: #252527;
  --bg4: #28282a;
  --bg5: #2d2d2f;
}

html {
  color-scheme: dark;
}

html,
body,
#__next,
main {
  width: 100%;
  height: 100%;
  margin: 0px;
}

.header {
  max-width: 500px;
  padding-inline: 2rem;
}

.header>img {
  display: block;
  margin: auto;
  max-width: 100%;
}

h1.err {
  text-align: center !important;
}

.tabs {
  position: relative;
  display: flex;
  align-content: space-between;

  >span {
    padding: 0.5rem;

    &[data-active] {
      text-decoration: underline var(--accent-bg);
    }
  }
}

.header>h2 {
  font-family: 'TWK Everett', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 144%;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1.5rem 2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  background-color: var(--lk-bg);
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

footer a,
h2 a {
  color: #ff6352;
  text-decoration-color: #a33529;
  text-underline-offset: 0.125em;
}

footer a:hover,
h2 a {
  text-decoration-color: #ff6352;
}

h2 a {
  text-decoration: none;
}

svg.lk-audio-visualizer {
  padding: 0 !important;
}

.lk-audio-visualizer>rect {
  opacity: 0;
}

.lk-audio-visualizer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: absolute;
  bottom: 0px;
  max-height: 10%;
  opacity: 0.3;
  margin: 0px !important;
}

[data-lk-quality='excellent'] {
  color: #06db4d;
}

[data-lk-quality='good'] {
  color: #f9b11f;
}

[data-lk-quality='poor'] {
  color: #f91f31;
}

[data-lk-quality='unknown'] {
  color: #f91f31;
}

[data-lk-speaking='true'] {
  --lk-accent-bg: #4caf4f;
  --lk-accent-bg2: #357b37;
  --lk-accent-bg3: #255527;
  --lk-accent-bg4: #1e4520;
  --lk-accent-bg5: #183619;
}

[data-lk-speaking='false'] {
  --lk-accent-bg: #4caf4f;
  --lk-accent-bg2: #357b37;
  --lk-accent-bg3: #255527;
  --lk-accent-bg4: #1e4520;
  --lk-accent-bg5: #183619;
}
